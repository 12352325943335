window.SegmentEvent = (function(){
    function trackLeadFormSubmit(form, context = {}) {
        const targetProps = {
            "name": "name",
            "firstname": "first_name",
            "first_name": "first_name",
            "lastname": "last_name",
            "last_name": "last_name",
            "email": "email",
            "phone number": "phone",
            "phone": "phone",
            "mobile phone": "phone",
            "mobilephone": "mobilephone",
            "country": "country",
            "marketing_data": "marketing_data",
        };
        
        form.addEventListener("submit", (e) => {
            try {
                let traits = {};

                form
                    .querySelectorAll("input[data-name], select[data-name]")
                    .forEach((field) => {
                        const fieldName = field.dataset.name.toLowerCase();

                        if (Object.keys(targetProps).includes(fieldName)) {
                            const value = field.value;

                            if (fieldName == "name") {
                                traits = getFirstLastName(traits, value);
                            } else if (targetProps[fieldName] == "phone") {
                                traits[targetProps[fieldName]] = standardisedPhoneNumber(value);
                            } else if (fieldName === "country") {
                                traits["address"] = traits["address"] || {};
                                traits["address"]["country"] = value;
                            } else {
                                traits[targetProps[fieldName]] = value;
                            }
                        }
                    });
                
                traits["form_name"] = form.name;
                traits["lead_type"] = "form";

                identify(traits, context);

                track("Lead Submitted", traits, context);
            } catch(e) {
                console.error(e);
            }
        });
    }

    function trackLeadSubmitted(traits) {
        track("Lead Submitted", traits);
    }

    function group(groupId, traits = {}, context = {}) {
        if (typeof analytics?.group === "function") {
            context = { ...context, groupId };
            analytics.group(groupId, traits, context);
        } else {
            console.error("Segment analytics was not initialized.");
        }
    }

    function track(eventName, traits, context = {}) {
        if (typeof analytics?.track === "function") {
            analytics.track(eventName, traits, { context });
        } else {
            console.error("Segment analytics was not initialized.");
        }
    }

    function identify(traits, context = {}) {
        if (typeof analytics?.identify === "function") {
            analytics.identify(traits, { context });
        } else {
            console.error("Segment analytics was not initialized.");
        }
    }

    function onAnalyticsReady(callbackFn) {
        if (typeof analytics?.ready === "function") {
            analytics && analytics.ready(callbackFn);
        } else {
            console.error("Segment analytics was not initialized.");
        }
    }

    return {
        trackLeadFormSubmit,
        onAnalyticsReady,
        trackLeadSubmitted,
        identify,
        group,
    };
})();