let hasInitSubmitClickListener = false;

window.CustomHubspotCode = (function () {
    function onFormSubmitted() {
        window.addEventListener("message", (event) => {
            const { data } = event;

            if (
                data.type === "hsFormCallback" &&
                data.eventName === "onFormSubmitted"
            ) {
                hsFormTrackLeadSubmitted(data);
                eoiFormRedirect(data, event);
                fireUpdateCompanyGroupEvent(event);
            }
        });
    }

    function onFormReady() {
        window.addEventListener("message", (event) => {
            const { data } = event;

            if (
                data.type === "hsFormCallback" &&
                data.eventName === "onFormReady"
            ) {
                populateHSHiddenFields();
                initAddressAutocomplete();
                triggerContactUsInitAutocompleteAddress();
                hsFormSubmitClickListener();
            }
        });
    }

    function triggerContactUsInitAutocompleteAddress() {
        const enquirySelect = document.querySelector('[name="what_is_the_nature_of_your_enquiry_"]');

        enquirySelect && enquirySelect.addEventListener('change', function () {
            if (this.value === 'Atlas Carbon grazier expression of interest') {
                setTimeout(() => {
                    initAddressAutocomplete();
                }, 100);
            } else {
                const manualAddressUi = document.querySelector('#manual-address-ui');
                manualAddressUi && manualAddressUi.remove();
            }
        });
    }

    function hsFormTrackLeadSubmitted(data) {
        const { id: formId, data: eventData } = data;
        const { submissionValues } = eventData;

        if (
            !("firstname" in submissionValues) ||
            !("lastname" in submissionValues) ||
            !("email" in submissionValues)
        ) {
            return;
        }

        const { firstname, lastname, email, mobilephone, phone } = submissionValues;
        const phoneValue = mobilephone || phone || "";

        const identifyTraits = {
            first_name: firstname,
            last_name: lastname,
            email,
            phone: phoneValue ? standardisedPhoneNumber(phoneValue) : "",
        }

        SegmentEvent.identify(identifyTraits);

        const props = {
            ...identifyTraits,
            lead_type: 'hs_form',
            form_id: formId,
            form_data: submissionValues,
        }

        SegmentEvent.trackLeadSubmitted(props);
    }

    function populateHSHiddenFields() {
        const poll = setInterval(function () {
            if (ClickCollector.getHasCollectedIds()) {
                WebflowCustomCode.addEnrichmentDataFields($('[action*="https://forms.hsforms.com"]'), true);

                clearInterval(poll);
            }
        }, 700);
    }

    function initAddressAutocomplete(options = {}) {
        const $form = $('[name="complete_address"]').closest('form[id^=hsForm_]');
        
        if ($form.length === 0) return;
        
        const manualAddressUi = document.querySelector('#manual-address-ui');

        if (manualAddressUi) return;
        
        const { googleAutocompleteType = 'address' } = options;

        insertManualAddressUI();
        insertErrorMessageUI('complete-address-error-msg', 'Please enter the street number.');
        insertErrorMessageUI('complete-address-select', 'Please select your address from the list or select below to manually input your address.');
        insertErrorMessageUI('complete-address-required-field', 'Please select your address from the list or select below to manually input your address.');
        initAutocompleteField($form, googleAutocompleteType);
    }

    function insertManualAddressUI() {
        const addressField = `
            <div id="manual-address-ui" class="hs_show_address_fields hs-show_address_fields hs-fieldtype-booleancheckbox field hs-form-field" >
                <legend class="hs-field-desc" style="display: none;"></legend>
                <div class="input">
                    <ul class="inputs-list">
                        <li class="hs-form-booleancheckbox">
                            <label for="show_address_fields-9dcd0afd-4c99-4fde-b363-ff551216fb9c" class="hs-form-booleancheckbox-display">
                                <input id="show_address_fields-9dcd0afd-4c99-4fde-b363-ff551216fb9c" class="hs-input" type="checkbox" name="show_address_fields" value="true" data-gtm-form-interact-field-id="1">
                                <span>Can't find your address? Enter it manually.</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        `

        $(addressField).insertAfter($('div.hs_complete_address'));
    }

    function insertErrorMessageUI(id, message) {
        const errorEl = `
            <ul class="no-list hs-error-msgs inputs-list custom-errors" role="alert" id="${id}" style="display: none">
                <li><label class="hs-error-msg hs-main-font-element">${message}</label></li>
            </ul>
        `;

        $(errorEl).insertAfter($('div.hs_complete_address div.input'));
    }

    function initAutocompleteField($form, type) {
        initializeAutocomplete(type);

        const geoFields = ['street_number', 'address', 'country', 'city', 'zip', 'state'];

        if ($form.find('[name="show_address_fields"]').is(':checked')) {
            showGeoFields(geoFields);
        } else {
            hideGeoFields(geoFields);
        }

        $form.find('[name="show_address_fields"]').change(function () {
            if ($(this).is(':checked')) {
                showGeoFields(geoFields);
            } else {
                hideGeoFields(geoFields);
            }
        });
    }

    function showGeoFields(geoFields) {
        geoFields.forEach(field => $(`[name=${field}]`).attr('type', 'text').closest('div.hs-form-field').show());
        $('#places-autocomplete-container').hide();
    }

    function hideGeoFields(geoFields) {
        geoFields.forEach(field => $(`[name=${field}]`).attr('type', 'text').closest('div.hs-form-field').hide());
        $('#places-autocomplete-container').show();
    }

    function eoiFormRedirect(data, event) {
        const { data: eventData } = data;
        const { submissionValues } = eventData;

        if ('grazing_area_size_ac_cbr' in submissionValues && 'annual_rainfall_cbr_ac' in submissionValues) {
            event.preventDefault();

            const { grazing_area_size_ac_cbr, annual_rainfall_cbr_ac } = submissionValues;

            if (annual_rainfall_cbr_ac >= 400 && grazing_area_size_ac_cbr >= 800) {
                const groupId = fireCreateCompanyGroupEvent(submissionValues);
                
                if (groupId !== null) {
                    window.location.href = `/cbr/property?group_id=${groupId}`;
                } else {
                    window.location.href = '/cbr/property';
                }

            } else if (grazing_area_size_ac_cbr >= 150) {
                window.location.href = '/cbr/maia-recommendation';
            } else if (grazing_area_size_ac_cbr < 150) {
                window.location.href = '/cbr/ineligible';
            } else {
                window.location.href = '/thank-you';
            }
        }
    }

    function fireCreateCompanyGroupEvent(submissionValues) {
        if ('firstname' in submissionValues && 'lastname' in submissionValues && 'email' in submissionValues) {
            const { firstname, lastname, email } = submissionValues;
            
            const groupId = generateUniqueId();

            SegmentEvent.group(groupId, {
                name: `${firstname} ${lastname}`,
                email,
            }, {
                email,
            });

            return groupId;
        }

        return null;
    }

    function fireUpdateCompanyGroupEvent(event) {
        const formId = event.data.id;
        const cbrForms = [
            '9dcd0afd-4c99-4fde-b363-ff551216fb9c', 
            'f3c79c91-5497-41d7-a539-99bf1a671e8b',
            '7f1eb7f2-fcc9-4ca0-87d3-a1041483670b',
            'cbd222ea-f454-4ff2-8a8f-c7383045afbe',
            'd7161b6e-9e4d-420f-85ca-7e844713492e',
            '061a0479-4fea-4e96-9902-f2e9c1f8b592',
        ];
        
        const groupId = getFromLocalStorage('ajs_group_id');

        if (!cbrForms.includes(formId) || groupId === null) return;

        const submissionValues = event.data.data.submissionValues;
        SegmentEvent.group(groupId, submissionValues);
    }

    function hsFormSubmitClickListener() {
        if (!hasInitSubmitClickListener) {
            hasInitSubmitClickListener = true;
        } else { 
            return;
        }

        const hsSubmitBtns = document.querySelectorAll('input.hs-button');

        if (hsSubmitBtns.length === 0) return;

        hsSubmitBtns.forEach(btn => {
            btn.addEventListener('click', function (e) {
                if (!hasSelectedAddress) {
                    showErrorMsg('complete-address-required-field');
                } else {
                    const form = btn.closest('form');
                    const completeAddress = form.querySelector('[name="complete_address"]');
                    const manualAddressCheckbox = form.querySelector('[name="show_address_fields"]');

                    if (completeAddress && manualAddressCheckbox) {
                        if (!manualAddressCheckbox.checked && completeAddress.value.trim() == '') {
                            e.preventDefault();
                            showErrorMsg('complete-address-required-field');
                        } else {
                            hideAllErrors();
                        }
                    }
                }
            });
        });
    }

    return {
        onFormSubmitted,
        onFormReady,
    };
})();

CustomHubspotCode.onFormSubmitted();
CustomHubspotCode.onFormReady();