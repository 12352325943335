function appendInputFieldToForm(form, name, value) {
    if (!form) return;

    const hiddenInput = document.createElement("input");

    hiddenInput.type = "hidden";
    hiddenInput.name = name;
    hiddenInput.dataset.name = name;
    hiddenInput.value = value;

    form.appendChild(hiddenInput);
}

function getFirstLastName(traits, fullName) {
    if (typeof fullName === "string" && fullName != "") {
        const first_name = fullName.split(" ").slice(0, -1).join(" ");
        const last_name = fullName.split(" ").slice(-1).join(" ");

        return { ...traits, first_name, last_name };
    }

    return traits;
}

function getFromLocalStorage(key) {
    if (!localStorage.getItem(key)) return null;

    return JSON.parse(localStorage.getItem(key));
}

function setToLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function standardisedPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.replace(/[ ]/g, "").replace("(0)", "");
    phoneNumber = phoneNumber.replace(/^00/, "+");
    phoneNumber = phoneNumber.replace(/[^\d\+]/g, "").trim();

    if (!phoneNumber.startsWith("+")) {
        phoneNumber = "+" + phoneNumber;
    }

    return phoneNumber == "+" ? "" : phoneNumber;
}

function setHSInputValue(form, name, value) {
    if (!form || value == null) return;
    
    const hsInput = form.find(`input[name*="${name}"]`);

    if (hsInput.length > 0 && value.trim() != "") {
        hsInput.val(value).change();
    }
}

function generateUniqueId() {
    const timestamp = Date.now().toString(36);
    const randomPart = Math.random().toString(36).substr(2, 5);
    return (timestamp + randomPart).slice(-12).padStart(12, '0');
}