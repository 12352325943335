document.addEventListener("DOMContentLoaded", (event) => {
    ClickCollector.recordClickIdsData();
});

let hasCollectedIds = false;
const clickCollectionKey = "ikrs:click_collection";
const clickIdsKey = "ikrs:click_ids";

window.ClickCollector = (function () {
    function getHasCollectedIds() {
        return hasCollectedIds;
    }

    function getClickIds() {
        return clickCollectionKey in localStorage
            ? getFromLocalStorage(clickCollectionKey)
            : { first: {}, last: {} };
    }

    function recordClickIdsData() {
        const clickIds = ["gclid", "fbclid", "mscklid", "client_id"];

        let clickIdsObj = getClickIds();

        const urlParams = new URLSearchParams(window.location.search);

        urlParams.forEach((value, key) => {
            if (value && (clickIds.includes(key) || key.startsWith("utm_"))) {
                if (!(key in clickIdsObj.first)) {
                    clickIdsObj.first[key] = value;
                }

                clickIdsObj.last[key] = value;
            }
        });

        localStorage.setItem(clickCollectionKey, JSON.stringify(clickIdsObj));
        hasCollectedIds = true;
    }

    return {
        recordClickIdsData,
        getHasCollectedIds,
        getClickIds,
    };
})();